import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";
import _ from "lodash";
import { Row, Div, Text } from "../../../styles/Common";
import { Button } from "../../../components/Button";
import { theme } from "../../../styles/theme";
import { isSuperAdmin, userClinic } from "../../../utilities/helpers";
import { LateralCard, QuotingCard, TotalCard } from "../CreatePackage/styles";
import { BilledInfo } from "../CreatePackage/components/BilledInfo/Index";
import { ItemsInfo } from "../CreatePackage/components/ItemsInfo/Index";
import ModalItems from "../CreatePackage/components/ModalItems";
import useGetClinicById from "../../Quoting Tool/hooks/useGetClinicById";
import useGetPackageById from "./hooks/useGetPackageById";
import { formatPriceToDollar } from "../../../utilities/helpers";
import useEditPackage from "./hooks/useEditPackage";
import useAddItem from "../../Quoting Tool/hooks/useAddItem";
import { validatePackageForm } from "../../../utilities/validations";
import ModalOpenItems from "../CreatePackage/components/ModalOpenItems";
import ModalCalculator from "../CreatePackage/components/ModalCalculator";

export const EditPackage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const superAdmin = isSuperAdmin();
  const queryClient = useQueryClient();

  const { common, packages } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};

  const packageId = packages?.selectedPackage?.id;

  const { name: userClinicName, id: userClinicId } = userClinic();

  const [forceUpdate, setForceUpdate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalOpenItems, setShowModalOpenItems] = useState(false);
  const [showModalSavings, setShowModalSavings] = useState(false);
  const [selectedSavings, setSelectedSavings] = useState({});
  const [packageName, setPackageName] = useState("");
  const [updatePackage, setUpdatePackage] = useState(false);

  const [selectedClinic, setSelectedClinic] = useState(
    superAdmin ? null : { value: userClinicId, label: userClinicName }
  );
  const [originalSelectedDueDate, setOriginalSelectedDueDate] = useState(
    dayjs().add(1, "month")
  );
  const [selectedDueDate, setSelectedDueDate] = useState(
    dayjs().add(1, "month")
  );

  useEffect(() => {
    if (!packages?.selectedPackage?.id) {
      navigate("/packages");
    } else {
      setOriginalSelectedDueDate(dayjs(packages?.selectedPackage?.dueDate));
      setSelectedDueDate(dayjs(packages?.selectedPackage?.dueDate));
    }
  }, [packages?.selectedPackage]);

  const addItem = useAddItem();
  const editPackage = useEditPackage();

  const getPackage = useGetPackageById(packageId);
  const { data: dataPackage, isLoading: isLoadingPackage } = getPackage;

  const getTaxClinic = useGetClinicById(selectedClinic?.value);
  const { data: dataTax } = getTaxClinic;
  const [clinicTax, setClinicTax] = useState(0);
  useEffect(() => {
    if (dataTax?.data?.data) {
      setClinicTax(dataTax?.data?.data?.taxes);
    }
  }, [dataTax]);

  const initialValues = {
    id: null,
    treatmentsCategories: [],
    treatments: [],
    productsCategories: [],
    products: [],
    packageName: packageName,
  };
  const [actualInfo, setActualInfo] = useState(initialValues);

  const initialValuesOpenItem = {
    type: "product",
    name: "",
    price: null,
    tax: clinicTax,
    isTaxIncluded: true,
  };
  const [actualInfoOpenItems, setActualInfoOpenItems] = useState(
    initialValuesOpenItem
  );
  useEffect(() => {
    setActualInfoOpenItems(initialValuesOpenItem);
  }, [clinicTax]);

  const itemInitialErrors = {
    treatmentsCategories: {
      error: false,
      message: "",
    },
    treatments: {
      error: false,
      message: "",
    },
    productsCategories: {
      error: false,
      message: "",
    },
    products: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [itemErrorForm, setItemErrorForm] = useState(itemInitialErrors);

  const openItemInitialErrors = {
    type: {
      error: false,
      message: "",
    },
    name: {
      error: false,
      message: "",
    },
    price: {
      error: false,
      message: "",
    },
    tax: {
      error: false,
      message: "",
    },
    isTaxIncluded: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [openItemErrorForm, setOpenItemErrorForm] = useState(
    openItemInitialErrors
  );

  const saveQuoteInitialErrors = {
    dueDate: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    items: {
      error: false,
      message: "",
    },
  };
  const [saveQuoteErrorForm, setSaveQuoteErrorForm] = useState(
    saveQuoteInitialErrors
  );

  const [originalQuote, setOriginalQuote] = useState({});
  const [quotation, setQuotation] = useState({
    clinicId: null,
    subtotal: 0,
    discount: 0,
    taxes: 0,
    total: 0,
    items: [],
  });
  const [originalOpenItems, setOriginalOpenItems] = useState([]);
  const [openItems, setOpenItems] = useState([]);

  useEffect(() => {
    if (dataPackage) {
      const initialQuotation = {
        id: dataPackage?.data?.data?.id,
        clinicId: dataPackage?.data?.data?.clinic?.id,
        subtotal: _.sumBy(
          dataPackage?.data?.data?.productsTreatments,
          (item) => item.price * Number(item.quantity)
        ),
        discount: _.sumBy(
          dataPackage?.data?.data?.productsTreatments,
          "discount"
        ),
        taxes: _.sumBy(dataPackage?.data?.data?.productsTreatments, "tax"),
        total: _.sumBy(
          dataPackage?.data?.data?.productsTreatments,
          (item) =>
            item.price * Number(item.quantity) + item.tax - item.discount
        ),
        items: dataPackage?.data?.data?.productsTreatments.map((item) => ({
          ...item,
          package: dataPackage?.data?.data?.name,
          quantity: Number(item.quantity),
        })),
      };
      setSelectedClinic({
        value: dataPackage?.data?.data?.clinic?.id,
        label: dataPackage?.data?.data?.clinic?.name,
      });

      setOriginalQuote(initialQuotation);
      setQuotation(initialQuotation);
      setOriginalOpenItems(
        dataPackage?.data?.data?.openItems.map((item) => ({
          ...item,
          isOpenItem: true,
          tax: clinicTax,
          isTaxIncluded: item.tax,
          package: dataPackage?.data?.data?.name,
          package: dataPackage?.data?.data?.name,
          quantity: Number(item.quantity),
          discount: 0,
        }))
      );
      setOpenItems(
        dataPackage?.data?.data?.openItems.map((item) => ({
          ...item,
          isOpenItem: true,
          tax: item.tax ? (clinicTax / 100) * item.price : 0,
          isTaxIncluded: item.tax,
          package: dataPackage?.data?.data?.name,
          quantity: Number(item.quantity),
          discount: item.discount,
        }))
      );
      setPackageName(dataPackage?.data?.data?.name);
    }
  }, [dataPackage, clinicTax]);

  const handleSelect = (value, option, id) => {
    switch (id) {
      case "clinic":
        setSelectedClinic(option);
        setQuotation(initialQuotation);
        setOpenItems([]);
        break;
      case "dueDate":
        const newErrorFormDD = { ...saveQuoteErrorForm };
        newErrorFormDD[id].error = false;
        newErrorFormDD[id].message = "";
        setSaveQuoteErrorForm(newErrorFormDD);
        setForceUpdate(!forceUpdate);
        setSelectedDueDate(value);
        break;
    }
    setForceUpdate(!forceUpdate);
  };

  const handleChangePackageName = (event) => {
    const { value, id } = event.target;
    const newErrorFormDD = { ...saveQuoteErrorForm };
    newErrorFormDD[id].error = false;
    newErrorFormDD[id].message = "";
    setSaveQuoteErrorForm(newErrorFormDD);

    setOpenItems((prevOpenItems) => [
      ...prevOpenItems.map((item) => ({ ...item, package: value })),
    ]);
    setQuotation({
      ...quotation,
      items: quotation.items.map((item) => ({
        ...item,
        package: value,
      })),
    });

    const newPackageName = value;
    setPackageName(newPackageName);
  };

  const handleCloseModal = () => {
    setActualInfo(initialValues);
    setItemErrorForm(itemInitialErrors);
    setActualInfoOpenItems(initialValuesOpenItem);
    setOpenItemErrorForm(openItemInitialErrors);
    setSelectedSavings({});
    setShowModal(false);
    setShowModalOpenItems(false);
    setShowModalSavings(false);
  };

  const calculateTotal = () => {
    let calculation =
      _.sumBy(
        quotation?.items,
        (item) => item.price * item.quantity + item.tax - Number(item.discount)
      ) +
      _.sumBy(
        openItems,
        (item) => item.price * item.quantity + item.tax - Number(item.discount)
      );

    calculation = Math.max(0, calculation);
    return formatPriceToDollar(calculation);
  };

  useEffect(() => {
    if (
      !_.isEqual(originalSelectedDueDate, selectedDueDate) ||
      JSON.stringify(quotation.items) !== JSON.stringify(originalQuote.items) ||
      JSON.stringify(openItems) !== JSON.stringify(originalOpenItems)
    ) {
      setUpdatePackage(true);
    } else {
      setUpdatePackage(false);
    }
  }, [quotation, openItems, selectedDueDate]);

  const handleSavePackage = () => {
    const saveQuoteForm = {
      dueDate: selectedDueDate,
      packageName: packageName,
      items:
        quotation.items.length > 0
          ? quotation.items.map((item) => ({
              id: Number(item.productTreatmentId),
              package: item.package,
              quantity: item.quantity,
            }))
          : openItems,
    };

    const validation = validatePackageForm.validate(saveQuoteForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = saveQuoteErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setSaveQuoteErrorForm(newErrorForm);
      });
      setForceUpdate(!forceUpdate);
    } else {
      editPackage.reset();
      editPackage.mutate(
        {
          formData: {
            clinicId: Number(selectedClinic.value),
            name: packageName,
            dueDate: selectedDueDate,
            productsTreatments: quotation.items.map((item) => ({
              productTreatmentId: Number(item.productTreatmentId),
              type: item.type,
              quantity: item.quantity,
              name: item.name,
              categoryId: item.categoryId,
              price: item.price,
              tax: item.tax,
              discount: Number(item.discount),
            })),
            openItems: openItems.map((item) => ({
              name: item.name,
              type: item.type,
              price: item.price,
              quantity: item.quantity,
              tax: item.isTaxIncluded,
              discount: Number(item.discount),
            })),
          },
          packageId: packageId,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries(["getPackageById"]);
            toast.success("The package has been updated successfully");
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.description ||
                err.response.data.error.message ||
                "Error trying to save the package"
            );
          },
        }
      );
    }
  };

  return (
    <Div height="max-content" width="1070px" direction="column">
      <ModalItems
        addItem={addItem}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedClinic={selectedClinic}
        actualInfo={actualInfo}
        errorForm={itemErrorForm}
        setErrorForm={setItemErrorForm}
        quotation={quotation}
        setQuotation={setQuotation}
        packageName={packageName}
      />
      <ModalOpenItems
        showModal={showModalOpenItems}
        handleCloseModal={handleCloseModal}
        actualInfo={actualInfoOpenItems}
        errorForm={openItemErrorForm}
        setErrorForm={setOpenItemErrorForm}
        setOpenItems={setOpenItems}
        clinicTax={clinicTax}
        packageName={packageName}
      />
      <ModalCalculator
        showModal={showModalSavings}
        handleCloseModal={handleCloseModal}
        selectedSavings={selectedSavings}
        quotation={quotation}
        setQuotation={setQuotation}
        openItems={openItems}
        setOpenItems={setOpenItems}
      />
      <Row justify="space-between" align="center" m="0px 0px 30px 0px">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Edit Package
          </Text>
        </Div>
      </Row>

      <Row gap="16px">
        <QuotingCard>
          <BilledInfo
            selectedClinic={selectedClinic}
            handleSelect={handleSelect}
            selectedDueDate={selectedDueDate}
            saveQuoteErrorForm={saveQuoteErrorForm}
            packageName={packageName}
            handleChangePackageName={handleChangePackageName}
          />
          <ItemsInfo
            setShowModal={setShowModal}
            setShowModalOpenItems={setShowModalOpenItems}
            setShowModalSavings={setShowModalSavings}
            quotation={quotation}
            setQuotation={setQuotation}
            saveQuoteErrorForm={saveQuoteErrorForm}
            openItems={openItems}
            setOpenItems={setOpenItems}
            GlobalPackageName={packageName}
            setSelectedSavings={setSelectedSavings}
          />
        </QuotingCard>

        <TotalCard>
          <LateralCard>
            <Row align="center" justify="space-between" m="0 0 24px 0">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                Amount due
              </Text>
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.sm}
                color={theme.colors.gray50}
              >
                {"(USD)"}
              </Text>
            </Row>
            <Row align="center" justify="space-between" m="0 0 12px 0">
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.default}
                color={theme.colors.gray50}
              >
                Subtotal
              </Text>
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.default}
                color={theme.colors.gray50}
              >
                {quotation.items.length === 0 && openItems.length === 0
                  ? formatPriceToDollar(0)
                  : formatPriceToDollar(
                      quotation.subtotal +
                        _.sumBy(openItems, (item) => item.price * item.quantity)
                    )}
              </Text>
            </Row>
            <Row align="center" justify="space-between" m="0 0 12px 0">
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.default}
                color={theme.colors.gray50}
              >
                Savings
              </Text>
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.default}
                color={theme.colors.gray50}
              >
                {quotation.items.length === 0
                  ? formatPriceToDollar(0)
                  : formatPriceToDollar(
                      _.sumBy(
                        quotation?.items.map((item) => Number(item.discount)),
                        (discount) => discount
                      ) +
                        _.sumBy(
                          openItems.map((item) => Number(item.discount)),
                          (discount) => discount
                        )
                    )}
              </Text>
            </Row>
            <Row
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
              m="0 0 12px 0"
            >
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.default}
                color={theme.colors.gray50}
              >
                Total Tax
              </Text>
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.default}
                color={theme.colors.gray50}
              >
                {quotation.items.length === 0 && openItems.length === 0
                  ? formatPriceToDollar(0)
                  : formatPriceToDollar(
                      quotation.taxes + _.sumBy(openItems, (item) => item.tax)
                    )}
              </Text>
            </Row>

            <Div
              height="1px"
              width="100%"
              background={theme.colors.gray500}
              m="0 0 19px 0"
            />
            <Row align="center" justify="space-between" m="0 0 19px 0">
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.default}
                color={theme.colors.gray50}
              >
                Total
              </Text>
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h3}
                color={theme.colors.green}
              >
                {quotation.items.length === 0 && openItems === 0
                  ? formatPriceToDollar(0)
                  : calculateTotal()}
              </Text>
            </Row>
          </LateralCard>
        </TotalCard>
      </Row>
      {updatePackage && (
        <Row align="center" m="35px 0px 37px 0px" gap={"14px"}>
          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            onClick={handleSavePackage}
            width="180px"
            background={theme.colors.green}
            m={"0 10px 0 0"}
            loading={editPackage.isLoading}
          >
            Edit Package
          </Button>
        </Row>
      )}
    </Div>
  );
};
